import { Slider } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import {
  ArticleIcon,
  PodcastIcon,
  RedirectIcon,
  VideoIcon,
} from '../../assets/svg';
import { ASSET_TYPE, validAssetTypes } from '../../common/constants';
import { convertSecondsToTime, numberToPercentage } from '../../common/utils';
import CustomTooltipTitle from './CustomTooltipTitle';

const AssetImage = ({ type, width, meta, imageUrl }) => {
  const defaultMinHeight = 136;
  const defaultMinWidth = width ?? '100%';

  const secondsDiff = meta?.endTime - meta?.startTime;

  // Convert seconds to HH:MM:SS format
  const timeDiff = secondsDiff > 0 ? convertSecondsToTime(secondsDiff) : null;
  const totalSeconds = meta?.startTime + meta?.endTime;

  // Convert seconds into percentage format for Slick bar
  const startTimePercentage = numberToPercentage(meta?.startTime, totalSeconds);
  const endTimePercentage = numberToPercentage(meta?.endTime, totalSeconds);

  const handlePosition = (percentage) => {
    const convertedPercentage = percentage - 10;
    if (convertedPercentage <= 0) {
      return 0;
    }
    return convertedPercentage;
  };

  const Controls = ({ assetType, diff, icon }) => (
    <>
      <p className="floating-tag">
        {icon}
        {capitalize(assetType)}
      </p>
      <p className="floating-zoom">
        <RedirectIcon fill="#ffffff" />
      </p>
      <div className="floating-seek-bar">
        {timeDiff && (
          <>
            <CustomTooltipTitle
              leftPosition={handlePosition(startTimePercentage)}
              time={diff}
            />
            <Slider
              range
              defaultValue={[startTimePercentage, endTimePercentage]}
              disabled
              tooltip={{ open: false }}
            />
          </>
        )}
      </div>
    </>
  );

  const renderAssets = (assetType) => {
    switch (assetType?.toUpperCase()) {
      case ASSET_TYPE.ARTICLE:
        return (
          <p className="floating-tag">
            <ArticleIcon />
            {type}
          </p>
        );
      case ASSET_TYPE.PDF:
        return (
          <p className="floating-tag">
            <ArticleIcon />
            {type}
          </p>
        );
      case ASSET_TYPE.VIDEO:
        return (
          <Controls
            assetType={assetType}
            diff={timeDiff}
            icon={<VideoIcon />}
          />
        );
      case ASSET_TYPE.PODCAST:
        return (
          <Controls
            assetType={assetType}
            diff={timeDiff}
            icon={<PodcastIcon />}
          />
        );
      default:
        return null;
    }
  };

  const renderClass = (value) => {
    const assetType = value?.toUpperCase();
    if (validAssetTypes?.includes(assetType) && timeDiff) {
      return 'overlay';
    }
  };

  return (
    <div className="lazy-load-wrapper">
      {renderAssets(type)}
      {imageUrl && (
        <img
          src={imageUrl}
          className={`image-wrapper ${renderClass(type)}`}
          alt="default placeholder"
          height={defaultMinHeight}
          width={defaultMinWidth}
        />
      )}
    </div>
  );
};

export default AssetImage;
